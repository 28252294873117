import React from 'react';
import { EscapeRoom, Preference, HowWork, MethodCta } from '../components';
import Layout from '../components/layout';

const ModszerunkPage = () => (
  <Layout
    seo={{
      title: 'Módszerünk',
      description:
        'Sikerélményt viszünk a fiatalok életébe szociális helyzettől függetlenül.'
    }}
  >
    <EscapeRoom />
    <Preference />
    <HowWork />
    <MethodCta />
  </Layout>
);

export default ModszerunkPage;
